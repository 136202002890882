/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Select, SelectProps, Typography } from 'antd';
import { useFela } from 'react-fela';
import {
  FieldError,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { FelaStyle } from '../../../fela';

const { Text } = Typography;

export type FormSelectProps<T extends FieldValues> = UseControllerProps<T> &
  SelectProps & {
    formatter?: (value: string) => string;
    showErrorMessage?: boolean;
  };

type FieldErrorType = string | FieldError | undefined;

export default function FormSelect<T extends FieldValues>({
  name,
  control,
  className,
  showErrorMessage = true,
  ...fieldProps
}: FormSelectProps<T>) {
  const {
    field: { ref, value, ...otherProps },
    formState: { errors },
  } = useController<T>({ name, control });
  const { css } = useFela();

  const error = errors[name];

  const errorType = error?.type;

  const selectStatus = (errorType: FieldErrorType) => {
    if (errorType === 'required-warning') {
      return 'warning';
    }
    if (errorType) {
      return 'error';
    }

    return undefined;
  };

  return (
    <>
      <Select
        ref={ref}
        className={className}
        status={selectStatus(errorType as FieldErrorType)}
        value={value}
        {...fieldProps}
        {...otherProps}
      />
      {showErrorMessage && (
        <Text className={css(styles.errorText)} type="danger">
          {error?.message}
        </Text>
      )}
    </>
  );
}

const styles: FelaStyle = {
  errorText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    margin: '0',
  },
};
