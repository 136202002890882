import React, { ReactNode, PropsWithChildren } from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import { FelaStyle, Style } from '../../../fela';
import colors from '../../colors';

export type ItemLabelProps = {
  felaStyle?: Style;
  label: string;
  required?: boolean;
  requiredText?: string;
  suffixContent?: ReactNode;
};

const { Text } = Typography;

export default function ItemLabel({
  children,
  felaStyle = {},
  label,
  required = true,
  requiredText,
  suffixContent,
}: PropsWithChildren<ItemLabelProps>) {
  const { css } = useFela();
  const { t } = useTranslation();

  const shouldShowOptionalText = !required || requiredText;
  const optionalText = requiredText || t('form.optional');

  return (
    <div className={css({ ...styles.container, ...felaStyle })}>
      <div className={css(styles.labelContainer)}>
        <Text className={css(styles.labelText)}>{label}</Text>
        <div className={css(styles.itemLabelSuffixContent)}>
          {suffixContent}
        </div>
        {shouldShowOptionalText && (
          <Text
            className={css({ ...styles.labelText, ...styles.optionalText })}
          >
            {optionalText}
          </Text>
        )}
      </div>
      {children}
    </div>
  );
}

const styles: FelaStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    margin: '0.5rem 0rem',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '.5rem',
    gap: '.25rem',
  },
  labelText: {
    color: colors.text.lightGray,
    fontSize: '.875rem',
    lineHeight: '1.375rem',
    marginBottom: '0',
  },
  optionalText: {
    color: colors.text.optional,
    paddingLeft: '.25rem',
  },
  itemLabelSufixContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '0.5rem',
    height: 'max-content',
  },
};
