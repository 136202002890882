import React, { ReactNode } from 'react';
import { Checkbox, CheckboxProps, Typography } from 'antd';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useFela } from 'react-fela';
import colors from '../../colors';

import { FelaFunction, FelaStyle } from '../../../fela';

const { Text } = Typography;

export type FormCheckboxProps<T extends FieldValues> = UseControllerProps<T> &
  CheckboxProps & { label?: ReactNode; suffixContent?: ReactNode };

export default function FormCheckbox<T extends FieldValues>({
  name,
  label,
  control,
  className,
  suffixContent,
  onChange = () => {},
}: FormCheckboxProps<T>) {
  const {
    field: { ref, value, onChange: onFieldChange, ...otherProps },
    formState: { errors },
  } = useController<T>({ name, control });

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    onChange(e);
    onFieldChange(e.target.checked);
  };

  const error = errors[name];
  const errorType = error?.type;

  const { css } = useFela({ errorType });

  return (
    <>
      <Checkbox
        ref={ref}
        className={className}
        checked={value}
        onChange={onCheckboxChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      >
        {label}
      </Checkbox>
      {suffixContent}
      <Text
        className={`${css(styles.errorText)} ${css(errorTextStyle)}`}
        type="danger"
      >
        {error?.message}
      </Text>
    </>
  );
}

const errorTextStyle: FelaFunction<{ errorType?: string }> = ({
  errorType,
}) => {
  if (!errorType) return {};

  if (errorType === 'oneOf') {
    return {
      color: colors.formItem.warning,
    };
  }

  return {
    color: colors.formItem.error,
  };
};

const styles: FelaStyle = {
  errorText: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.375rem',
    margin: '0',
  },
};
